// const IndexPage = () => (
//   <Layout>
//     <SEO title="Home" />
//     <h1>Hi people</h1>
//     <p>Welcome to your new Gatsby site.</p>
//     <p>Now go build something great.</p>
//     <StaticImage
//       src="../images/gatsby-astronaut.png"
//       width={300}
//       quality={95}
//       formats={["AUTO", "WEBP", "AVIF"]}
//       alt="A Gatsby astronaut"
//       style={{ marginBottom: `1.45rem` }}
//     />
//     <p>
//       <Link to="/page-2/">Go to page 2</Link> <br />
//       <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
//     </p>
//   </Layout>
// )

// export default IndexPage


import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

import { Container, Row, Col } from "react-bootstrap"

const IndexPage2 = () => {
  const { placeholderImage123 } = useStaticQuery(
          graphql`
      query {
        placeholderImage123: file(relativePath: { eq: "index-image.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 35, 
              webpOptions: {quality: 50}, 
              width: 2000,
              placeholder: BLURRED,
              blurredOptions: {width: 400},
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const pluginImage = getImage(placeholderImage123);
  // const crowdImage = getImage(crowd);

  return (
    <Layout>
      <SEO title="Engage. Inspire. Motivate." />
      <BgImage image={pluginImage} className="masthead">
        <div className="color-overlay d-flex pt-4 justify-content-center align-items-center">
          <div className="content-box">
            <Container>
              <h1>Engage. Inspire. Motivate.</h1>
              {/* <Link to="/services/" className="btn btn-outline-light">Learn More</Link> */}
            </Container>
          </div>
        </div>
      </BgImage>
      <div className="orangeBG py-3 px-1 text-center">
        <h2>Voted Best Event Production Services Company Of Marin 2020!</h2>
      </div>
      <Container>
        <h2 className="orangeBarBelow mb-4">We can help</h2>
        <Row  className="d-flex justify-content-center align-items-center">
          <Col lg={6} className="py-3">
            <h2>Meeting Planning</h2>
            <p className="lead">You need a meeting with marked results. Our professionalism, dedication and experience are key to helping make that happen.</p>
            {/* <Link to="/services/" className="btn btn-outline-dark">Learn More</Link> */}
          </Col>
          <Col lg={6}>
            <StaticImage
              src="../images/meeting-planning.jpg"
              width={700}
              quality={72}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="A Corporate Meeting"
              className="rounded"
            />
          </Col>
        </Row>

        <Row  className="d-flex justify-content-center align-items-center">
          <Col lg={6} className="order-1 order-md-2 py-3">
            <h2>Event Creation</h2>
            <p className="lead">You have business goals to meet, people to incent and a great event idea that will bring the two together. The next step is finding a partner to listen and understand your vision, who is capable of managing the details to make it come to life.</p>
            {/* <Link to="/services/" className="btn btn-outline-dark">Learn More</Link> */}
          </Col>
          <Col lg={6} className="order-2 order-md-1">
            <StaticImage
              src="../images/event-creation.jpg"
              width={700}
              quality={72}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="A Corporate Meeting"
              className="rounded"
            />
          </Col>
        </Row>
        <Row  className="d-flex justify-content-center align-items-center">
          <Col lg={6} className="py-3">
            <h2>Incentive Planning</h2>
            <p className="lead">Recognize and reward your top performers with an experience they will not soon forget!</p>
            {/* <Link to="/services/" className="btn btn-outline-dark">Learn More</Link> */}
          </Col>
          <Col lg={6}>
            <StaticImage
              src="../images/incentive-planning.jpg"
              width={700}
              quality={72}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="A Corporate Meeting"
              className="rounded"
            />
          </Col>
        </Row>
      </Container>
      {/* <BgImage image={crowdImage}>
        Test
        <div className="color-overlay d-flex py-4 justify-content-center align-items-center">
          <div className="content-box">
            <Container>
              <p className="lead"><em>"Safely Processing and Delivering Superior Value and Service to Our Customers for over 100 years."</em></p>
            </Container>
          </div>
        </div>
      </BgImage> */}
      {/* crowd.jpg */}
    </Layout>
          
  )
}

export default IndexPage2